// src/hooks/usePaginatedData.tsx

import { useState, useEffect } from 'react';
import ApiService from '../services/apiService';

interface UsePaginatedDataProps<T> {
  endpoint: string;
  itemsPerPage?: number;
}

interface PaginatedData<T> {
  data: T[];
  loading: boolean;
  error: string | null;
  next: string | null;
  previous: string | null;
  currentPage: number;
  totalPages: number;
  fetchPage: (page: number) => void;
  handlePageChange: (urlOrPageNumber: string | number) => void;
  getPageUrl: (pageNumber: number) => string;
}

function usePaginatedData<T>({ endpoint, itemsPerPage = 10 }: UsePaginatedDataProps<T>): PaginatedData<T> {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [next, setNext] = useState<string | null>(null);
  const [previous, setPrevious] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  // Função para ajustar URLs, se necessário
  const replaceUrl = (url: string) => {
    // Ajuste a URL base conforme necessário
    return url.replace('http://genesis:5000/', '');
  };

  const extractPageNumber = (url: string | null): number | null => {
    if (!url) return null;
    try {
      const cleanUrl = replaceUrl(url);
      const urlObj = new URL(cleanUrl, window.location.origin);
      return parseInt(urlObj.searchParams.get('page') || '1', 10);
    } catch (error) {
      console.error('Erro ao extrair número da página:', error);
      return null;
    }
  };

  const getPageUrl = (pageNumber: number): string => {
    const baseUrl = endpoint.split('?')[0];
    const queryParams = endpoint.includes('?') ? endpoint.split('?')[1] : '';
    const urlParams = new URLSearchParams(queryParams);
    urlParams.set('page', pageNumber.toString());
    return `${baseUrl}?${urlParams.toString()}`;
  };

  const fetchData = async (url: string) => {
    try {
      setLoading(true);
      const response = await ApiService.get<{
        count: number;
        next: string | null;
        previous: string | null;
        results: T[];
      }>(replaceUrl(url));
      setData(response.results);
      setNext(response.next);
      setPrevious(response.previous);

      const totalItems = response.count;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));

      // Determinar a página atual
      const pageFromUrl = extractPageNumber(url);
      setCurrentPage(pageFromUrl || 1);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Erro ao buscar dados.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (urlOrPageNumber: string | number) => {
    let url: string;
    if (typeof urlOrPageNumber === 'number') {
      url = getPageUrl(urlOrPageNumber);
    } else {
      url = replaceUrl(urlOrPageNumber);
    }
    fetchData(url);
  };

  const fetchPage = (page: number) => {
    const url = getPageUrl(page);
    fetchData(url);
  };

  useEffect(() => {
    fetchData(endpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint]);

  return {
    data,
    loading,
    error,
    next,
    previous,
    currentPage,
    totalPages,
    fetchPage,
    handlePageChange,
    getPageUrl,
  };
}

export default usePaginatedData;
