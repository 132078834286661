import React from 'react';
import { Link } from 'react-router-dom';

interface MenuItemProps {
  to: string;
  label: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ to, label }) => {
  return (
    <li className="nav-item">
      <Link className="nav-link" to={to}>
        {label}
      </Link>
    </li>
  );
};

export default MenuItem;
