import React, { useState, useEffect } from 'react';
import './Table.css';

interface TableProps {
  data: any[];
  includeCheckbox?: boolean;
  onSelectionChange?: (selectedItems: any[]) => void; // Nova prop para informar itens selecionados
}

const Table: React.FC<TableProps> = ({ data, includeCheckbox = false, onSelectionChange }) => {
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    if (onSelectionChange) {
      const selectedData = data.filter((_, index) => selectedItems.has(index));
      onSelectionChange(selectedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    if (checked) {
      // Selecionar todos
      const allIndexes = data.map((_, index) => index);
      setSelectedItems(new Set(allIndexes));
    } else {
      // Desmarcar todos
      setSelectedItems(new Set());
    }
  };

  const handleCheckboxChange = (index: number) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      setSelectAll(newSet.size === data.length);
      return newSet;
    });
  };

  const renderTableHeader = () => {
    if (data.length === 0) return null;
    const headers = Object.keys(data[0]);
    return (
      <tr>
        {includeCheckbox && (
          <th>
            <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
          </th>
        )}
        {headers.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    );
  };

  const renderTableData = () => {
    return data.map((item, index) => (
      <tr key={index}>
        {includeCheckbox && (
          <td>
            <input
              type="checkbox"
              checked={selectedItems.has(index)}
              onChange={() => handleCheckboxChange(index)}
            />
          </td>
        )}
        {Object.values(item).map((value, idx) => (
          <td key={idx}>{String(value)}</td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="generic-table">
      {data.length === 0 ? (
        <p>Dados não disponíveis</p>
      ) : (
        <table className="table table-bordered table-hover table-sm">
          <thead>{renderTableHeader()}</thead>
          <tbody>{renderTableData()}</tbody>
        </table>
      )}
    </div>
  );
};

export default Table;
