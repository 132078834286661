import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

// const available_paths = {
//   profile: true,
//   admin: true
// };

// const MenuItem = ({ name }: any) => (
//   <div>
//     {name}
//   </div>
// );

// const TMenu = () => (
//   <>
//     {JSON.stringify(Object.keys(available_paths))}
//     {Object.keys(available_paths).map((path: string, index: number) => {
//       // @ts-ignore
//       const link = available_paths[path] ? path : undefined;
//       return link && <MenuItem key={`${path}-${index}`} name={path} />;
//     })}
//   </>
// );

const HomePage: React.FC = () => {
  const fullText = 'MasterControl';
  const initialText = 'MC';

  const [displayedText, setDisplayedText] = useState<string>(initialText);

  useEffect(() => {
    const displayedTextArray: string[] = new Array(fullText.length).fill('');
    const initialIndices: number[] = [];

    // Definir a letra 'M' na posição correta
    displayedTextArray[0] = 'M';
    initialIndices.push(0);

    // Definir a letra 'C' na posição correta
    displayedTextArray[6] = 'C';
    initialIndices.push(6);

    // Criar uma lista de índices a serem preenchidos
    const indicesToFill: number[] = [];
    for (let i = 0; i < fullText.length; i++) {
      if (!initialIndices.includes(i)) {
        indicesToFill.push(i);
      }
    }

    let currentIndex = 0;

    const typingEffect = setInterval(() => {
      if (currentIndex < indicesToFill.length) {
        const indexToFill = indicesToFill[currentIndex];
        displayedTextArray[indexToFill] = fullText[indexToFill];
        setDisplayedText(displayedTextArray.join(''));
        currentIndex++;
      } else {
        clearInterval(typingEffect);
      }
    }, 125); // Ajuste a velocidade da digitação aqui

    return () => clearInterval(typingEffect);
  }, []);

  return (
    <div className="home-container d-flex flex-column justify-content-center align-items-center vh-100">
      <h1 className="text-center display-1">{displayedText}</h1>
      <p className="text-center lead">Gerenciando o processo de forma eficiente</p>
      <Link to="/login" className="btn btn-primary mt-3">
        Login
      </Link>
      {/* <TMenu /> */}
    </div>
  );
};

export default HomePage;
