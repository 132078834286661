// src/pages/genesis/GenesisPage.tsx
import React from 'react';
import usePaginatedData from '../../hooks/usePaginatedData';
import ApiService from '../../services/apiService';
import Table from '../../components/table';
import Menu from '../../components/menu';
import Button from '../../components/button';
import Pagination from '../../components/pagination';
import refresh from '../../assets/refresh.png';
import transfer from '../../assets/transfer.png';

interface GenesisPageProps {
  endpoint: string;
}

const GenesisPage: React.FC<GenesisPageProps> = ({ endpoint }) => {
  const { data, loading, next, previous, currentPage, totalPages, handlePageChange, getPageUrl } =
    usePaginatedData<any>({ endpoint });

  const buttons = [
    {
      // Atualizar o endpoint específico
      onClick: async () => {
        await ApiService.get(`genesis/update/${endpoint.split('/')[1]}`);
      },
      image: refresh
    },
    {
      // Adaptar o endpoint específico
      onClick: async () => {
        await ApiService.get(`genesis/adapt/${endpoint.split('/')[1]}`);
      },
      image: transfer
    }
  ];

  return (
    <div>
      <Menu />
      <div className="container-fluid">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <Button buttons={buttons} />
          <div className="pagination-container">
            <Pagination
              next={next}
              previous={previous}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
              getPageUrl={getPageUrl}
            />
          </div>
        </div>
        {loading ? <p>Carregando...</p> : <Table data={data} includeCheckbox={true} />}
      </div>
    </div>
  );
};

export default GenesisPage;
