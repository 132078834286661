// src/components/Dashboard.tsx
import React from 'react';
import './Dashboard.css';
import Messages from '../messages';

const Dashboard: React.FC = () => {
  return (
    <div>
      <div className="container mt-5">
        <h1>Bem-vindo!</h1>
        {/* <Messages /> */}
      </div>
    </div>
  );
};

export default Dashboard;
