// src/pages/vendor/index.tsx
import React from 'react';
import PaginatedTablePage from '../../components/paginatedTablePage';

interface VendorPageProps {
  endpoint: string;
}

const VendorPage: React.FC<VendorPageProps> = ({ endpoint }) => {
  return <PaginatedTablePage endpoint={endpoint} includeCheckbox={true} />;
};

export default VendorPage;