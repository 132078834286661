// apiService.tsx
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { LoginRequest } from '../types';

class ApiService {
  private static instance: AxiosInstance;

  private constructor() {}

  public static getInstance(): AxiosInstance {
    if (!ApiService.instance) {
      ApiService.instance = axios.create({
        baseURL: 'https://erp.cpel.ind.br/api/',
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 60 * 1000, // 1 minuto
        timeoutErrorMessage:
          'Tempo limite excedido. Verifique sua conexão com a internet e tente novamente.'
      });

      ApiService.instance.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
          const token = localStorage.getItem('access');
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
    return ApiService.instance;
  }

  public static async login(request: LoginRequest): Promise<void> {
    const response = await ApiService.getInstance().post('login/', request);
    localStorage.setItem('access', response.data.access);
    localStorage.setItem('refresh', response.data.refresh);
  }

  public static async refreshToken(): Promise<void> {
    const refreshToken = localStorage.getItem('refresh');
    if (refreshToken) {
      try {
        const response = await ApiService.getInstance().post('token/refresh/', {
          refresh: refreshToken
        });
        localStorage.setItem('access', response.data.access);
        ApiService.instance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
      } catch (error) {
        await ApiService.logout();
        window.location.href = '/login';
        throw new Error('Sessão expirada. Faça login novamente.');
      }
    } else {
      console.error('Token de renovação não encontrado.');
      await ApiService.logout();
      window.location.href = '/login';
      throw new Error('Sessão expirada. Faça login novamente.');
    }
  }

  public static async post<T>(url: string, data: any): Promise<AxiosResponse<T>> {
    try {
      const response = await ApiService.getInstance().post<T>(url, data);
      return response;
    } catch (error: any) {
      console.error('Erro na requisição POST:', error);
      if (error.response && error.response.status === 401) {
        await ApiService.refreshToken();
        try {
          const newResponse = await ApiService.getInstance().post<T>(url, data);
          return newResponse;
        } catch (newError: any) {
          if (newError.response && newError.response.status === 401) {
            await ApiService.logout();
            window.location.href = '/login';
            throw new Error('Sessão expirada. Faça login novamente.');
          }
          throw newError;
        }
      }
      throw error;
    }
  }

  public static async get<T>(url: string): Promise<T> {
    try {
      const response = await ApiService.getInstance().get<T>(url);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await ApiService.refreshToken();
        try {
          const retryResponse = await ApiService.getInstance().get<T>(url);
          return retryResponse.data;
        } catch (retryError: any) {
          if (retryError.response && retryError.response.status === 401) {
            await ApiService.logout();
            window.location.href = '/login';
            throw new Error('Sessão expirada. Faça login novamente.');
          }
          throw retryError;
        }
      }
      throw error;
    }
  }

  public static async put<T>(url: string, data: any): Promise<T> {
    try {
      const response = await ApiService.getInstance().put<T>(url, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await ApiService.refreshToken();
        try {
          const newResponse = await ApiService.getInstance().put<T>(url, data);
          return newResponse.data;
        } catch (newError: any) {
          if (newError.response && newError.response.status === 401) {
            await ApiService.logout();
            window.location.href = '/login';
            throw new Error('Sessão expirada. Faça login novamente.');
          }
          throw newError;
        }
      }
      throw error;
    }
  }

  public static async delete<T>(url: string): Promise<T> {
    try {
      const response = await ApiService.getInstance().delete<T>(url);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await ApiService.refreshToken();
        try {
          const newResponse = await ApiService.getInstance().delete<T>(url);
          return newResponse.data;
        } catch (newError: any) {
          if (newError.response && newError.response.status === 401) {
            await ApiService.logout();
            window.location.href = '/login';
            throw new Error('Sessão expirada. Faça login novamente.');
          }
          return newError;
        }
      }
      throw error;
    }
  }

  public static async logout(): Promise<void> {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
  }
}

export default ApiService;
