// src/components/table/filter/index.tsx

import React, { useState } from 'react';

interface TableFilterProps {
  data: any[];
  onFilter: (filteredData: any[]) => void;
}

const TableFilter: React.FC<TableFilterProps> = ({ data, onFilter }) => {
  const [filterText, setFilterText] = useState<string>('');

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setFilterText(text);

    const filteredData = data.filter((item) =>
      Object.values(item).some((value) => String(value).toLowerCase().includes(text.toLowerCase()))
    );
    onFilter(filteredData);
  };

  return (
    <div className="table-filter mb-3">
      <input
        type="text"
        className="form-control"
        placeholder="Filtrar..."
        value={filterText}
        onChange={handleFilterChange}
      />
    </div>
  );
};

export default TableFilter;