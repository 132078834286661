import React from 'react';
import LoginForm from '../../components/login';
import './LoginPage.css';

const LoginPage: React.FC = () => {
  return (
    <div className="login-page align-items-center justify-content-center vh-100">
      <main className="form-signin">
        <LoginForm />
      </main>
    </div>
  );
};

export default LoginPage;
