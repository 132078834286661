import React from 'react';
import Menu from '../../components/menu';
import Dashboard from '../../components/dashboard';
import './DashboardPage.css';

const DashboardPage: React.FC = () => {
  return (
    <div>
      <Menu />
      <div>
        <Dashboard />
      </div>
    </div>
  );
};

export default DashboardPage;
