// src/pages/product/index.tsx

import React from 'react';
import PaginatedTablePage from '../../components/paginatedTablePage';

interface OrderPageProps {
  endpoint: string;
}

const OrderPage: React.FC<OrderPageProps> = ({ endpoint }) => {
  return <PaginatedTablePage endpoint={endpoint} includeCheckbox={true} />;
};

export default OrderPage;
