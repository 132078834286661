import React, { useEffect, useState } from 'react';
import ApiService from '../../services/apiService';
import Menu from '../../components/menu';
import Table from '../../components/table';
import Pagination from '../../components/pagination';

interface RHPageProps {
  endpoint: string;
}

const RHPage: React.FC<RHPageProps> = ({ endpoint }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [next, setNext] = useState<string | null>(null);
  const [previous, setPrevious] = useState<string | null>(null);

  const fetchData = async (url: string) => {
    try {
      const response = await ApiService.get<{
        count: number;
        next: string | null;
        previous: string | null;
        results: any[];
      }>(url);
      setData(response.results);
      setNext(response.next);
      setPrevious(response.previous);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(endpoint);
  }, [endpoint]);

  const handlePageChange = (url: string) => {
    setLoading(true);
    fetchData(url);
  };
  return (
    <div>
      <Menu />
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          {/* <Pagination next={next} previous={previous} onPageChange={handlePageChange} /> */}
        </div>
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <>
            <Table data={data} includeCheckbox={true} />
          </>
        )}
      </div>
    </div>
  );
};

export default RHPage;
