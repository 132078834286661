import React from 'react';
import { Link } from 'react-router-dom';

interface DropdownMenuProps {
  label: string;
  items: { to: string; label: string }[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ label, items }) => {
  return (
    <li className="nav-item dropdown">
      <button
        className="nav-link dropdown-toggle btn btn-link"
        id="navbarDropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {label}
      </button>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        {items.map((item, index) => (
          <li key={index}>
            <Link className="dropdown-item" to={item.to}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default DropdownMenu;
