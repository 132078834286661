// src/pages/supplier/index.tsx

import React from 'react';
import PaginatedTablePage from '../../components/paginatedTablePage';

interface SupplierPageProps {
  endpoint: string;
}

const SupplierPage: React.FC<SupplierPageProps> = ({ endpoint }) => {
  return <PaginatedTablePage endpoint={endpoint} includeCheckbox={true} />;
};

export default SupplierPage;
