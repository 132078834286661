// src/pages/client/index.tsx

import React from 'react';
import PaginatedTablePage from '../../components/paginatedTablePage';

interface ClientPageProps {
  endpoint: string;
}

const ClientPage: React.FC<ClientPageProps> = ({ endpoint }) => {
  return <PaginatedTablePage endpoint={endpoint} includeCheckbox={true} />;
};

export default ClientPage;