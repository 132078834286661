import React from 'react';
import leftArrow from '../../assets/left-arrow.png';
import rightArrow from '../../assets/right-arrow.png';
import './Pagination.css';

interface PaginationProps {
  next: string | null;
  previous: string | null;
  onPageChange: (urlOrPageNumber: string | number) => void;
  currentPage: number;
  totalPages: number;
  getPageUrl: (pageNumber: number) => string;
}

const Pagination: React.FC<PaginationProps> = ({
  next,
  previous,
  onPageChange,
  currentPage,
  totalPages,
  getPageUrl
}) => {
  const replace_url = (url: string) => {
    return url.replace('http://genesis:5000/', '');
  };

  const pageNumbers = [];
  const maxPageNumbersToShow = 5;
  const halfPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

  let startPage = currentPage - halfPageNumbersToShow;
  let endPage = currentPage + halfPageNumbersToShow;

  if (startPage < 1) {
    startPage = 1;
    endPage = Math.min(totalPages, maxPageNumbersToShow);
  }

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber: number) => {
    const url = getPageUrl(pageNumber);
    onPageChange(url);
  };

  const handlePreviousClick = () => {
    if (previous) {
      const newUrl = replace_url(previous);
      onPageChange(newUrl);
    }
  };

  const handleNextClick = () => {
    if (next) {
      const newUrl = replace_url(next);
      onPageChange(newUrl);
    }
  };

  return (
    <>
      {(next || previous) && totalPages > 1 ? (
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-center m-3">
            {/* Botão Anterior com Imagem */}
            <li className={`page-item ${!previous ? 'disabled' : ''}`}>
              <button className="page-link" onClick={handlePreviousClick} disabled={!previous}>
                <img src={leftArrow} alt="Anterior" width="20" height="20" />
              </button>
            </li>

            {/* Primeira Página */}
            <li className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageClick(1)}>
                1
              </button>
            </li>

            {/* Reticências Antes */}
            {startPage > 2 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}

            {/* Páginas Intermediárias */}
            {pageNumbers.map((number) => {
              if (number !== 1 && number !== totalPages) {
                return (
                  <li
                    key={number}
                    className={`page-item ${currentPage === number ? 'active' : ''}`}
                  >
                    <button className="page-link" onClick={() => handlePageClick(number)}>
                      {number}
                    </button>
                  </li>
                );
              }
              return null;
            })}

            {/* Reticências Depois */}
            {endPage < totalPages - 1 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}

            {/* Última Página */}
            {totalPages > 1 && (
              <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageClick(totalPages)}>
                  {totalPages}
                </button>
              </li>
            )}

            {/* Botão Próximo com Imagem */}
            <li className={`page-item ${!next ? 'disabled' : ''}`}>
              <button className="page-link" onClick={handleNextClick} disabled={!next}>
                <img src={rightArrow} alt="Próximo" width="20" height="20" />
              </button>
            </li>
          </ul>
        </nav>
      ) : null}
    </>
  );
};

export default Pagination;
